import '../App.css';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
const MobileHeader = () => {
    const navigate = useNavigate();
    const navigateHome = () => {
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.width = '';
        navigate("/");
    }
    const navigateAbout = () => {
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.width = '';
        navigate("/About");
    }
    const navigatePortfolio = () => {
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.width = '';
        navigate("/Portfolio");
        
    }
    const [menu, showMenu] = useState(false);

    const setMenu = (t) => {
      showMenu(t)
      if(t == true){
       
        document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%'; 
      }
      else{
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.width = '';
      }
    }
  return (
   <>
    {!menu &&   <div className='mobile-header'>
        <div className='mobile-header-half'>
          <p className='mobile-header-title' onClick={navigateHome}>Elliot Friesen</p>
        </div>
        <div className='mobile-header-half1' style={{justifyContent: 'flex-end'}}>
          <img src={require('../menu.png')} className='mobile-header-ham' onClick={() => setMenu(true)}/>
        </div>
       
      </div> }
      {menu && <div className='mobile-menu'>
      <div className='mobile-header'>
        <div className='mobile-header-half'>
         
        </div>
        <div className='mobile-header-half' style={{justifyContent: 'flex-end'}}>
          <img src={require('../x.png')} className='mobile-header-ham' onClick={() => setMenu(false)}/>
        </div>
         
      </div> 
      <div className='menu-item-holder'>
        <p className='menu-item' onClick={navigateHome}>Home</p>
        <p className='menu-item' onClick={navigatePortfolio}>Portfolio</p>
        <p className='menu-item' onClick={navigateAbout}>About Me</p>
        </div>
      </div> }

      </>
  );
}

export default MobileHeader;
