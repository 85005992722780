import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import React, { useState, useEffect, useRef } from 'react';
import Footer from './components/Footer';
import {useLocation} from 'react-router-dom';
import { motion, useInView } from 'framer-motion';
import MobileHeader from './components/MobileHeader';
function Project() {
  const imageVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeInOut' } },
  };
  const mainImageRef = useRef(null);
  const mainImageInView = useInView(mainImageRef, { once: true, threshold: 0.9 });

  const image1Ref = useRef(null);
  const image1InView = useInView(image1Ref, { once: true, threshold: 0.9 });

  const image3Ref = useRef(null);
  const image3InView = useInView(image3Ref, { once: true, threshold: 0.9 });

  const image5Ref = useRef(null);
  const image5InView = useInView(image5Ref, { once: true, threshold: 0.9 });

  const sideImage0Ref = useRef(null);
  const sideImage0InView = useInView(sideImage0Ref, { once: true, threshold: 0.9 });

  const sideImage4Ref = useRef(null);
  const sideImage4InView = useInView(sideImage4Ref, { once: true, threshold: 0.9 });

  const sideImage2Ref = useRef(null);
  const sideImage2InView = useInView(sideImage2Ref, { once: true, threshold: 0.9 });
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const location = useLocation();
    const i = location.state.i;
  const titles = ["Edu-Gold.com", "Graphing Animation Software", "Syringe Driver", "Health AI", "Internship Project", "Python Class Final Project AI", "This Portfolio Website"]
  const details = ["I developed EduGold with the goal of modernizing the college tour process. In short, EduGold connects high schoolers with college students based on their interests for personalized college tours. I developed the site using React for the front end and Firebase for the backend. The platform includes an end-to-end payment system, scheduling features, user authentication, an automated email alert system, and much more.", 
    "This is a web-based graphing platform that lets users create key-frame animations by graphing equations. The goal of the site is to better the learning of middle and high school students by teaching math in a more fun and creative medium. I developed it throughout my junior year of high school.", 
    "I designed this hardware to automate the process of pumping a syringe in order to simplify the testing process of a medical device. It's designed to be 3D printed and uses minimal parts. One of the key design aspects of this project was developing a 3d printed coupler that can connect the syringe to the linear actuator.",
     "This is a REST API that predicts conditions of users based on symptoms that they provide. In developing this API, I used the NLTK library for Natural Language Processing, enabling users to simply describe how they are feeling. The AI then extracts key symptoms from their testimony and feeds this into a Random Forest Classifier created with scikit-learn. Once conditions are predicted, they are sent as a response to the user. I created the dataset for this project through web-scraping from WebMD and Mayo Clinic.", 
     "For this internship, I was tasked with developing new features that could be implemented within the Tufts Mobile App. I then presented these features to the company as well as the Tufts Mobile App Development Team. In creating the prototypes, I conducted a student survey and analyzed responses. I then developed the features using the Modo Platform for the front end and AWS Lambda and DynamoDB for the backend.",
    "For my final project in a freshman year python course, I examined the relationships between countries economic factors and their varying mental health rates. For the first part of my project, I used Matplotlib and GeoPandas to visualize the data through various plots. This visualization was followed by the development of a feed-forward neural network that I created using tensorflow. This model allowed me to predict mental health rates within specific countries to a somewhat high accuracy based on their economic situations.",
  "I developed my portfolio with React:)"]

     const technologies = [
        "ReactJS, JavaScript, HTML, CSS, Stripe API, & Firebase (Firestore, Authentication, Storage, & Hosting)",
        "HTML, CSS, PHP, JavaScript, & MySQL",
        "SolidWorks, Arduino, & Tinkercad", 
        "Python, Flask, sckit-learn, NLTK, FuzzyWuzzy, Pandas, & Selenium",
        "React, OpenAI API, & AWS (Lambda, DynamoDB, & CloudWatch)",
        "Python, Tensorflow, GeoPandas, Matplotlib, Pandas, & Plotly",
        "React, HTML, CSS, JavaScript, & Firebase Hosting"
     ]

     const imageLinks = [
        [require("./projects/edugold/0.png"), require("./projects/edugold/1.png"), require("./projects/edugold/2.png"), require("./projects/edugold/3.png")],
        [require("./projects/mathimate/0.png"), require("./projects/mathimate/1.png"), require("./projects/mathimate/2.png")],
        [require("./projects/syringe_driver/0.png"), require("./projects/syringe_driver/1.png"), require("./projects/syringe_driver/2.png"), require("./projects/syringe_driver/3.png"), require("./projects/syringe_driver/4.png")],
        [require("./projects/health/0.png"), require("./projects/health/1.png"), require("./projects/health/2.png")],
        [],
        [require("./projects/es2/0.png"), require("./projects/es2/1.png"), require("./projects/es2/2.png"), require("./projects/es2/3.png"), require("./projects/es2/4.png"), require("./projects/es2/5.png")],
        []
        
     ]
     const imageMainLinks =  [require("./projects/edugold/main.png"), require("./projects/mathimate/main.png"), require("./projects/syringe_driver/main.png"), require("./projects/health/main.png"), require("./projects/internship/main.png"), require("./projects/es2/main.png"), require("./projects/this/main.png")]
    
  return (
    <div className='portfolio-main'>
      <MobileHeader />
     <Header />
     <div className='project-header'>
     <p className='project-header-title'>
    {titles[i]}
     </p>
     <p className='project-section-header'>
    Project Details
     </p>
     <p className='project-section-info'>
    {details[i]}
     </p>
     {i === 4 && <p className='project-section-header'>
    Additional Internship Project
     </p>}
     {i === 4 && <p className='project-section-info'>
      I was the co-manager of a web project where my team and I implemented the Open AI Assistant API into a react application to be embedded into the Modo Platform. During this process, I taught other interns the basics of React. For this project, I was responsible for implementing the middleware, in this case meaning connecting the OpenAI API to the React front end, which I successfully completed.
     </p> }
     <p className='project-section-header'>
    Technologies     
    </p>
     <p className='project-section-info'>
    {technologies[i]}
     </p>
     </div>
    <div className='project-image-holder'>
    <div className='project-grid-holder'>
        {i !== 4 && i !==  6 && 
        <div className='project-grid-side'>
            <motion.img
        src={imageMainLinks[i]}
        className="project_img"
        variants={imageVariants}
        initial="hidden"
        animate={mainImageInView ? "visible" : "hidden"}
        ref={mainImageRef}
      />
      <motion.img
        src={imageLinks[i][1]}
        className="project_img"
        variants={imageVariants}
        initial="hidden"
        animate={image1InView ? "visible" : "hidden"}
        ref={image1Ref}
      />
      {imageLinks[i][3] && (
        <motion.img
          src={imageLinks[i][3]}
          className="project_img"
          variants={imageVariants}
          initial="hidden"
          animate={image3InView ? "visible" : "hidden"}
          ref={image3Ref}
        />
      )}
      {imageLinks[i][5] && (
        <motion.img
          src={imageLinks[i][5]}
          className="project_img"
          variants={imageVariants}
          initial="hidden"
          animate={image5InView ? "visible" : "hidden"}
          ref={image5Ref}
        />
        
      )}
      </div> }
      {i !== 4 && i !== 6 && (
        <div className="project-grid-side1">
          <motion.img
            src={imageLinks[i][0]}
            className="project_img"
            variants={imageVariants}
            initial="hidden"
            animate={sideImage0InView ? "visible" : "hidden"}
            ref={sideImage0Ref}
          />
          {imageLinks[i][4] && (
            <motion.img
              src={imageLinks[i][4]}
              className="project_img"
              variants={imageVariants}
              initial="hidden"
              animate={sideImage4InView ? "visible" : "hidden"}
              ref={sideImage4Ref}
            />
          )}
          <motion.img
            src={imageLinks[i][2]}
            className="project_img"
            variants={imageVariants}
            initial="hidden"
            animate={sideImage2InView ? "visible" : "hidden"}
            ref={sideImage2Ref}
          />
       </div> 
      )}
            
            {i == 4 && <div className='vid'>
                <iframe
        
        src={`https://www.youtube.com/embed/kA_0LdQyP00`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
                </div> }
        </div> 
    </div>
     <Footer />
    </div>
  );
}

export default Project;
