import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import React, { useState, useEffect, useRef } from 'react';
import Footer from './components/Footer';
import { motion } from 'framer-motion';
import MobileHeader from './components/MobileHeader';
function Home() {
  const [menu, showMenu] = useState(false);

  const setMenu = (t) => {
    showMenu(t)
  }
  return (
    <div className='main-holder'>
    <MobileHeader />
    <div className="main1">
     <Header />
     <div className='main-side'>
     <motion.svg
      className="svg"
      width="588"
      height="459"
      viewBox="0 0 588 459"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M588 333.667C588 460.416 517.392 458.999 333.31 458.999C149.228 458.999 0 356.249 0 229.5C0 102.751 149.228 0 333.31 0C517.392 0 588 206.918 588 333.667Z"
        fill="none"
        stroke="#9A68EA"
        strokeWidth="3"
        initial={{ pathLength: 0, fill: "rgba(154, 104, 234, 0)" }}
        animate={{ pathLength: 1, fill: "rgba(154, 104, 234, 1)" }}
        transition={{
          pathLength: { duration: 2, ease: "easeInOut" },
          fill: { delay: 2, duration: 1 }
        }}
      />
    </motion.svg>

  <img src={require('./menobg.png')} className='profilephoto'/>
     </div>
     <div className='main-side'>
      <div className='main-text-center'>
        <p className='main-text-header'>Hey, I'm Elliot Friesen</p>
        <p className='main-text-details'>Full Stack Web Developer - App Developer - Hardware - AI</p>
        <p className='main-text-additional-details'>I am a sophomore at Tufts University with five years of experience in the software development industry
        across a variety of individual projects, freelance work, and professional settings  </p>
      </div>
     </div>
     
     </div>
     <div className="main3">
     <Header />
    
     <div className='main-side'>
      <div className='main-text-center'>
        <p className='main-text-header2'>Hey, I'm Elliot Friesen</p>
        <p className='main-text-details2'>Full Stack Web Developer - App Developer - Hardware - AI</p>
        <p className='main-text-additional-details2'>I am a sophomore at Tufts University with five years of experience in the software development industry
        across a variety of individual projects, freelance work, and professional settings. </p>
      </div>
     </div>
     <div className='main-side'>
     <motion.svg
      className="svg"
      width="588"
      height="459"
      viewBox="0 0 588 459"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M588 333.667C588 460.416 517.392 458.999 333.31 458.999C149.228 458.999 0 356.249 0 229.5C0 102.751 149.228 0 333.31 0C517.392 0 588 206.918 588 333.667Z"
        fill="none"
        stroke="#9A68EA"
        strokeWidth="3"
        initial={{ pathLength: 0, fill: "rgba(154, 104, 234, 0)" }}
        animate={{ pathLength: 1, fill: "rgba(154, 104, 234, 1)" }}
        transition={{
          pathLength: { duration: 2, ease: "easeInOut" },
          fill: { delay: 2, duration: 1 }
        }}
      />
    </motion.svg>

  <img src={require('./menobg.png')} className='profilephoto'/>
     </div>
     </div>
     <Footer />
    </div>
  );
}

export default Home;
