import '../App.css';
import React from 'react';

import { useNavigate } from 'react-router-dom';
const Footer = () => {
   
  return (
   
     
<div className='footer'>
<div className='footer-center'>
    <div className='footer-third'>
        <div className='footer-third-half'>
            <p className='footer-header-text'>Call</p>
        </div>
        <div className='footer-third-half'>
        <p className='footer-info-text'>845-826-1115</p>
        </div>
    </div>
    <div className='footer-third'>
        <div className='footer-third-half'>
        <p className='footer-header-text'>Email</p>
        </div>
        <div className='footer-third-half'>
        <p className='footer-info-text'>elliot.friesen7@gmail.com</p>
        </div>
    </div>
    <div className='footer-third'>
        <div className='footer-third-half'>
        <p className='footer-header-text'>Linkedin</p>
        </div>
        <div className='footer-third-half'>
        <a className='footer-info-text' href="https://www.linkedin.com/in/elliot-friesen-6970a8240" target="_blank">in</a>
        </div>
    </div>
</div>
</div>
      
  );
}

export default Footer;
