import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Portfolio from './Portfolio';
import Project from './Project';
function App() {
  
  return (

    <Router>
    <div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/Project" element={<Project />} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
