import '../App.css';
import React from 'react';

import { useNavigate } from 'react-router-dom';
const Header = () => {
    const navigate = useNavigate();
    const navigateHome = () => {
        navigate("/");
    }
    const navigateAbout = () => {
        navigate("/About");
    }
    const navigatePortfolio = () => {
        navigate("/Portfolio");
    }
  return (
   
      <div className='header'>
        <div className='header-side title-holder'>
          <p className='title' onClick={navigateHome}>Elliot Friesen</p>
        </div>
        <div className='header-side header-right'>
          <div className='header-third'>
            <p className='header-button' onClick={navigateHome}>Home</p>
          </div>
          <div className='header-third'>
          <p className='header-button'  onClick={navigatePortfolio}>Portfolio</p>
          </div>
          <div className='header-third'>
          <p className='header-button' onClick={navigateAbout}>About Me</p>
          </div>
        </div>
      </div>

      
  );
}

export default Header;
